.userTable {
	height: 100%;
	width: 100%;
}

.userTable__button {
	height: 5%;
}

.userTable__table {
	height: 95%;
}

.userTable__bigTable {
	height: 100%;
}

.userTable__csvLink {
	text-decoration: none;
	color: black;
}

.userTable__popper {
	padding: .6rem;
	border-radius: 6px;
	background-color: var(--primary);
	color: var(--font);
}
