@font-face {
	font-family: 'Segoe UI Semilight';
	src: url('../src/assets/fonts/segoeuisl.ttf');
}

:root {
	--primary: #35b4a8;
	--secondary: rgb(250, 174, 57);
	--gray: #d9d9d9;
	--gray-light: #f2f2f2;
	--gray-darker: rgb(151, 151, 151);
	--gray-dark: rgb(78, 78, 78);
	--font: rgb(255, 255, 255);
}

* {
	font-family: 'Segoe UI Semilight';
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
