.flavourSettings__button {   
	margin: 1rem 0rem; 
}

.flavourSettings__div {
	display: flex;
	flex-direction: column; 
	margin: 1rem 0rem;
}
.flavourSettings__label {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}