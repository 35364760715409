h3 {
	margin-bottom: 0px;
}

.productPopup__tableheader {
	display: flex;
	background-color: var(--primary);
	height: 2.5rem;
	padding: 0 0.5rem;
	align-items: center;
	color: var(--font);
}

.productPopup__tablerow {
	display: flex;
	align-items: center;
	padding: 0 0.5rem;
	height: 1.8rem;
}

.productPopup__tablerow:nth-child(odd) {
	background-color: var(--gray);
}
