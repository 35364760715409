.userEditForm__container {
	margin-top: 1rem;
}
	
.userEditForm__form {
	display: flex;
	flex-direction: column;
}

.userEditForm__buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: right;
}

.userEditForm__button {
	margin-top: 1rem;
}