.imagePlaceholder {
	display: flex;
	position: relative;
	justify-content: center;
	width: 75%;
	align-items: center;
}

.imagePlaceholder::before {
	content: '';
	display: block;
	padding-top: 100%;
}

.imagePlaceholder > * {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
