.navbar {
	display: flex;
	flex-direction: row;
	height: 3.5rem;
	justify-content: space-between;
	align-items: center;
	background-color: var(--gray-light);
	padding-right: 2rem;
	box-sizing: border-box;
}

.navbar__container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	max-width: 25rem;
}

.navbar__row {
	display: flex;
	align-items: center;
	height: 100%;
}

.navbar__logo {
	height: 100%;
}

.navbar__logo > img {
	height: 100%;
}

.navbar__combobox {
	width: 15rem;
}

.navbar__link {
	cursor: pointer;
}