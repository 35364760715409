.kundenanlage__button {
	margin-top: 1rem;
}

.kundenanlage__container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.kundenanlage__label {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}