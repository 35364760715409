.routes__sidebarContainer {
	flex-shrink: 0;
	transition: 0.5s ease;
}

.routes__container {
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
}

.routes__contentContainer {
	height: calc(100% - 3.5rem);
	height: -webkit-calc(100% - 3.5rem);
	height: -moz-calc(100% - 3.5rem);
	box-sizing: border-box;
	padding: 1rem;
	overflow: scroll;
	overflow-x: hidden;
}

.routes__contentContainer::-webkit-scrollbar-track {
	background-color: white;
}

.routes__contentContainer::-webkit-scrollbar {
	width: 12px;
}

.routes__contentContainer::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: var(--gray-dark);
}
