.createFlavor {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.createFlavor__cardContainer {
	width: 100%;
	max-width: 25rem;
	min-width: 15rem;
}

.createFlavor__form {
	display: flex;
	flex-direction: column;
}

.createFlavor__button {
	margin-top: 1rem;
}
