.userDataCard__root {
	margin-top: 1rem;
}

.userDataCard__outerWrapper {
	display: flex;
	flex-direction: column;
}

.userDataCard__innerWrapper {
	display: flex;
	justify-content: space-around;
}
