.productAvailability__noFlavorSelected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.productAvailability__tagContainer {
	display: flex;
	align-items: center;
}

.productAvailability__tag {
	margin-right: 0.5rem;
}
