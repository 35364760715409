.loginScreen {
	max-width: 500px;
	margin: auto;
	margin-top: 12px;
	padding: 2rem;
}

.loginScreen__form {
	display: flex;
	flex-direction: column;
}

.loginScreen__textBox {
	padding-top: 1rem;
}

.loginScreen__actionbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
}

.flavorChoiceScreen {
	max-width: 500px;
	margin: auto;
	margin-top: 12px;
	padding: 2rem;
}

.flavorChoiceScreen__form {
	display: flex;
	flex-direction: column;
}

.flavorChoiceScreen__actionbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
}
