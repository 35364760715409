.orderDocument {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: white;
	width: 100%;
	max-width: 300px;
	align-items: center;
}

.orderDocument__dashedLines {
	width: 100%;
	height: 3px;
	border-top: 1px dashed #999999;
}

.orderDocument__header {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 1rem;
	align-items: center;
}

.orderDocument__header > p {
	margin: 0;
	padding: 0;
}

.orderDocument__orderDetails {
	width: 100%;
	padding-top: 1rem;
}

.orderDocument__pickupCode {
	margin-top: 2rem;
	display: flex;
	width: 100%;
	justify-content: center;
}

.orderDocument__pickupCode > p {
	font-size: larger;
	font-weight: bold;
}

.orderDocument__qrcode {
	width: 128px;
	height: 128px;
	background-color: aqua;
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.orderDocument__article {
	width: 100%;
}

.orderDocument__article > td {
	font-size: 10px;
}

.orderDocument__footer {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	width: 100%;
	padding-bottom: 1rem;
}

.orderDocument__center {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.orderDocument__center > p {
	margin: 0;
	padding: 0;
}

.orderDocument__dateTime {
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
}

.orderDocument__dateTime > div {
	margin-right: 1rem;
}

.orderDocument__dateTime > div > p {
	margin: 0;
	padding: 0;
	font-size: 14px;
}