.userActionBar {
	display: flex;
	height: 4rem;
	align-items: center;
}

.userActionBar > Button {
	background-color: lightgray;
	margin-right: 1rem;
	height: 2.3rem;
	margin-right: 2rem;
}
