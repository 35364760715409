.emailserversmtp__button {
	margin-top: 1rem;
}

.emailserversmtp__container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.emailserversmtp__label {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}