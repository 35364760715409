.forgotPassword {
	max-width: 500px;
	margin: auto;
	margin-top: 1rem;
	padding: 2rem;
}

.forgotPassword__form {
	display: flex;
	flex-direction: column;
}

.forgotPassword__textBox {
	margin-top: 1rem;
}

.forgotPassword__actionbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2rem;
}
