.product {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid var(--gray-dark);
	border-radius: 6px;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.product__imgcontainer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.product__imgcontainer > img {
	max-width: 75%;
}

.product__namecontainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
	padding: 0 0.5rem;
	background-color: var(--gray-light);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.product__notAvailableContainer {
	position: relative;
}
