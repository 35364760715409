.kundenanlage__noFlavorSelected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.kundenanlage__gridItem {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.kundenanlage__subgridItem {
	width: 100%; 
	display: flex;
	flex-direction: column;
	align-items: center;
}

.kundenanlage__header {
	font-size: 20;
	font-weight: bold;
}

.kundenanlage__card {
	width: 75%;
}
