.category {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid var(--gray-dark);
	border-radius: 6px;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.category__idcontainer {
	display: flex;
	position: absolute;
	background-color: var(--gray-light);
	width: 2rem;
	height: 2rem;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	border-top-left-radius: 4px;
}

.category__imgcontainer {
	display: flex;
	justify-content: center;
}

.category__imgcontainer > img {
	max-width: 75%;
}

.category__namecontainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
	background-color: var(--gray-light);
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
