.infoRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 1rem 0rem;
}

.infoRow__name {
	width: 100%;
	padding: 0 1rem;
}

.infoRow__initial {
	color: gray;
}

.infoRow__loading {
	color: blue;
}

.infoRow__success {
	color: green;
}

.infoRow__error {
	color: red;
}