.branchOrders {
	height: 100%;
}

.branchOrders__popper {
	padding: 0.6rem;
	border-radius: 6px;
	background-color: var(--primary);
	color: var(--font);
}
