.branch__noFlavorSelected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.branches {
	width: 100%;
	height: 100%;
}

.branch__table {
	height: 100%;
}

.branch__time {
	display: flex;
	align-items: center;
	height: 1rem;
}
