th {
	text-align: left;
}

hr {
	width: 90%;
	opacity: 60%;
}

.orderHistoryDialog__dialogTitle {
	background-color: var(--gray-light);
	margin-top: 2rem;
}

.orderHistoryDialog__branchInfo {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.orderHistoryDialog__text {
	margin-left: 5%;
}

.orderHistoryDialog__table {
	width: 90%;
	margin: auto;
}

.orderHistoryDialog__column10 {
	width: 15%;
}

.orderHistoryDialog__column70 {
	width: 55%;
}

.orderHistoryDialog__priceSummary {
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: auto;
}

.orderHistoryDialog__textAlignRight {
	text-align: right;
}

.orderHistoryDialog__sum {
	font-size: 1.2rem;
	font-weight: bold;
}
