.sidebar {
	position: fixed;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: var(--gray);
	overflow: hidden;
}

.sidebar__stateChangeButton {
	height: 3.5rem;
	display: flex;
	margin-left: 0.6rem;
	justify-content: flex-start;
	align-items: center;
}

.sidebar__container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
}

.sidebar__menuContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sidebar__menuItem {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem 0;
}

.sidebar__menuItem:hover {
	cursor: pointer;
	background-color: var(--gray-darker);
}

.sidebar__menuIcon {
	min-width: 3.5rem;
	font-size: 2rem;
}

.sidebar__menuIconImg {
	height: 2.5rem;
	width: 2.5rem;
	margin-left: 0.5rem;
}

.sidebar__menuText {
	margin-left: 1rem;
}

.sidebar__selected {
	background-color: var(--gray-darker);
}

.linecontainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1.8rem;
	width: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	transition: 0.5s;
	padding: 0.01rem;
	cursor: pointer;
}

.open {
	transform: rotate(-180deg);
}

.open > #top {
	transform: rotate(45deg) translate(0.43rem) scaleX(0.6);
}

.open > #middle {
	transform: scaleX(0.78);
}

.open > #bottom {
	transform: rotate(-45deg) translate(0.43rem) scaleX(0.6);
}

.line {
	height: 2px;
	width: 1.6rem;
	background-color: black;
	transition: 0.5s;
}
