.toaitgrid {
	height: 660px;
	width: 100%;
}
 
.qrcodegrid__div{
	display: flex;
	flex-direction: column; 
	padding: 1rem;
	border-radius: 1rem;
}
.qrcodegrid__grid{
	display: flex;
	width: 100%;
	align-content: center; 
	justify-content: center;
	align-items: center;  
	justify-items: center;
}
.qrcodegrid__text{
	font-weight: bold; 
	justify-self: center;
	align-self: center; 
}

.qrcodegrid__popper {
	padding: 0.6rem;
	border-radius: 6px;
	background-color: var(--primary);
	color: var(--font);
}
 