.customerCard {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.8rem;
}

.customerCard__card {
	display: flex;
	flex-direction: row;
	max-height: 160px;
	padding: 1rem;
	border-radius: 1rem;
	background-color: var(--primary);
}

.customerCard__right {
	margin-left: 1rem;
}

.customerCard__right > * {
	color: white;
}

.customerCard__name {
	font-weight: bold;
}

.customerCard__qrcode {
	border-radius: 6px;
}

.customerCard__blocked {
	background-color: red;
	font-weight: bold;
	color: white;
	font-size: 1.2re;
}
