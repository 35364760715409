.fullSync {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.fullSync__row {
	width: 100%;
}

.fullSync__button {
	margin-top: 1rem;
}

.fullSync__hr {
	opacity: 0.1;
}

.fullSync__label {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}