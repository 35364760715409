.grid__title {
    font-weight: bold;
    margin: 1.5% 2%;
  }
 
.branchtables_grid{
  display: flex;
	flex-direction: column;
}

.t_textfield
{  
  margin: 1.5% 2%;
    font-weight: bold; 
    justify-self: center;
    align-self: center; 
     
}

.t_textfield_url{

  width: 400px;
}

.to__button {
	margin-top: 1rem;
}