.userRegistration__noFlavorSelected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.userRegistration {
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.userRegistration__card {
	width: 100%;
	max-width: 25rem;
	min-width: 15rem;
	padding: 1rem;
	box-sizing: border-box;
}

.userRegistration__form {
	display: flex;
	flex-direction: column;
}

.userRegistration__button {
	margin-top: 1rem;
}

.userRegistration__tabPanel {
	height: 80%;
}

.userRegistration__invitationCard {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 25rem;
	min-width: 15rem;
	padding: 1rem;
	box-sizing: border-box;
}